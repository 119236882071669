import { createSlice } from "@reduxjs/toolkit";
import { ACCESS_TOKEN, USER, UserState } from "../constants";
import { fetchLogin } from "../redux/fetchLogin";

export const initialState: UserState = {
    pending: false,
    isLoggedIn: Boolean(localStorage.getItem(ACCESS_TOKEN)),
    user: null,
    errorMessage: null,
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        resetAuth: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLogin.pending, (state) => {
            state.pending = true;
            state.isLoggedIn = false;
        });
        builder.addCase(fetchLogin.fulfilled, (state, { payload }) => {
            if (payload.errorMessage) {
                localStorage.setItem(ACCESS_TOKEN, JSON.stringify(false));
                state.isLoggedIn = false;
                state.pending = false;
                state.user = null;
                state.errorMessage = payload.errorMessage;
            } else {
                state.pending = false;
                localStorage.setItem(ACCESS_TOKEN, JSON.stringify(true));
                localStorage.setItem(USER, JSON.stringify(payload.user));

                state.isLoggedIn = true;
                state.user = payload.user;
                state.errorMessage = null;
            }
        });
        builder.addCase(fetchLogin.rejected, (state, { payload }) => {
            localStorage.setItem(ACCESS_TOKEN, JSON.stringify(false));
            state.pending = false;
            state.user = null;
            state.errorMessage = payload as string;
        });
    },
});

const { reducer } = loginSlice;
export const { resetAuth } = loginSlice.actions;

export default reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { useCheckFeatureFlagOn } from "hooks/useCheckFeatureFlagOn";
import {
    findAssetsResponse,
    findAssetsChineseResponse,
} from "mocks/fetchSitesResponse";
import { RootState } from "../../../redux/store";
import { SiteState } from "../constants";
import { SiteList } from "../types";

export const fetchSites = createAsyncThunk<
    SiteState,
    { search?: string; changeLanguageFlag?: boolean },
    { state: RootState }
>(
    "dashboard/fetchSites",
    async ({ search, changeLanguageFlag }, { rejectWithValue }) => {
        try {
            let response: SiteState;
            if (changeLanguageFlag) {
                response = {
                    sites: findAssetsChineseResponse.findSites.site,
                    lastUpdated: Date.now(),
                    pending: false,
                    error: "",
                };
            } else {
                response = {
                    sites: findAssetsResponse.findSites.site,
                    lastUpdated: Date.now(),
                    pending: false,
                    error: "",
                };
            }
            console.log("changeLanguageFlag " + changeLanguageFlag, response);
            return response;
        } catch (err) {
            return rejectWithValue("Failed to fetch");
        }
    }
);

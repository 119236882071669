import React, { Fragment, ReactChild } from "react";
import Box from "@carrier-io/fds-react/Box";
import Divider from "@carrier-io/fds-react/Divider";
import Skeleton from "@carrier-io/fds-react/Skeleton";

interface SubHeaderProps {
  items: ReactChild[];
  pending?: boolean;
}

export const PageSubHeader = ({ items, pending }: SubHeaderProps) =>
  pending ? (
    <Skeleton data-testid="loader" width={300} height={26} />
  ) : (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {items.map((item, index, arr) => {
        const lastItem = index === arr.length - 1;
        return (
          <Fragment key={index}>
            {item}
            {!lastItem && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ margin: "0 12px" }}
              />
            )}
          </Fragment>
        );
      })}
    </Box>
  );

import { SiteList } from "./types";

export interface SiteState {
    sites: SiteList;
    lastUpdated: number;
    pending: boolean;
    error: string;
}

export enum SiteTabs {
    All = "All",
    Connected = "Connected",
    Offline = "Offline",
}

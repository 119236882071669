import { Components } from "@carrier-io/fds-react/styles";

export const components: Components = {
    MuiAlert: {
        styleOverrides: {
            root: {
                "& .MuiAlert-message": { width: "100%" },
            },
        },
    },
};

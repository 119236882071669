import { CSSObject } from "@carrier-io/fds-react/styles";
export type CSSProperties<T extends string> = Record<
    T,
    CSSObject | (() => CSSObject) | ((data: unknown) => CSSObject)
>;
export default {
    container: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginBottom: "30px",
    },
    chatBtn: {
        "&:hover": {
            background: "none",
        },
        svg: {
            width: "30px",
            height: "30px",
        },
    },
} as CSSProperties<"container" | "chatBtn">;

import { FC } from "react";
import { fleetThemeOptions } from "@carrier-io/fds-react/theme";
import FleetThemeProvider from "@carrier-io/fds-react/theme/FleetThemeProvider";
import FleetLocalizationProvider from "@carrier-io/fds-react/FleetLocalizationProvider";
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider,
} from "@carrier-io/fds-react/styles";
import { themeOptions } from "./constants/themeOptions";

const combinedTheme = createTheme(fleetThemeOptions, themeOptions);

export const DemoThemeProvider: FC = ({ children }) => {
    return (
        <FleetLocalizationProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={combinedTheme}>{children}</ThemeProvider>
            </StyledEngineProvider>
        </FleetLocalizationProvider>
    );
};

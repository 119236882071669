import {
    combineReducers,
    configureStore,
    PreloadedState,
} from "@reduxjs/toolkit";

import { loginReducer } from "../features/login";
import { dashboardReducer } from "../features/dashboard";
import chatBot from "../features/chatBot/redux/slice";

export const rootReducer = combineReducers({
    login: loginReducer,
    dashboard: dashboardReducer,
    chatBot,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,

        preloadedState,
    });
};

const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export default store;

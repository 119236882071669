import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ChatBotState {
    nonAuthBotActive?: boolean;
    authBotActive?: boolean;
}

const initialState: ChatBotState = {
    nonAuthBotActive: false,
    authBotActive: false,
};

export const chatBotSlice = createSlice({
    name: "chatBot",
    initialState,
    reducers: {
        setNonAuthBotActive: (
            state: ChatBotState,
            { payload }: PayloadAction<boolean>
        ): void => {
            state.nonAuthBotActive = payload;
        },
        setAuthBotActive: (
            state: ChatBotState,
            { payload }: PayloadAction<boolean>
        ): void => {
            state.authBotActive = payload;
        },
    },
});

export default chatBotSlice.reducer;
export const { setNonAuthBotActive, setAuthBotActive } = chatBotSlice.actions;

import { Fragment, ReactText } from "react";
import Box from "@carrier-io/fds-react/Box";
import Typography from "@carrier-io/fds-react/Typography";

import { ReactComponent as Attention } from "../../assets/icon/Attention.svg";
import { TooltipProps } from "@carrier-io/fds-react/Tooltip";
import { isEmptyValue } from "../../helpers";

interface NoDataValueWrapperProps {
    children?: ReactText | boolean | null;
    /**
     * Show tooltip on hover over attention icon. If `withTooltip` set to `false`, then error message will be shown after icon
     * @default true
     */
    withTooltip?: boolean;
    tooltipPlacement?: TooltipProps["placement"];
    /**
     * Error message that will be shown in tooltip or after icon
     * @default "No data to display"
     */
    message?: string;
}

export const NoDataValueWrapper = ({
    children,
    withTooltip = true,
    tooltipPlacement = "top",
    message = "No data to display",
}: NoDataValueWrapperProps) => {
    const emptyValue = isEmptyValue(children);

    if (!emptyValue) {
        return <Fragment>{children}</Fragment>;
    }
    return withTooltip ? (
        <></>
    ) : (
        <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
            <Box component="span" sx={{ display: "flex", marginRight: "7px" }}>
                <Attention />
            </Box>
            <Typography component="span" variant="body2">
                {message}
            </Typography>
        </Box>
    );
};

import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { enUS } from "date-fns/locale";

// https://github.com/date-fns/date-fns/issues/1706
const getCustomFormat = (unit: string, count: number) => {
  switch (unit) {
    case "xSeconds":
      return "1m";
    case "xMinutes":
      return `${count}m`;
    case "xHours":
      return `${count}h`;
    case "xDays":
      return `${count}d`;
    case "xMonths":
      return `${count}m`;
    case "xYears": {
      return `${count}y`;
    }
  }
};

export function getCustomFormattedDistanceToNow(
  date: Date | number = Date.now()
): string {
  const customOptions = {
    includeSeconds: true,
    addSuffix: true,
    locale: {
      ...enUS,
      formatDistance: (unit: string, count: number) => {
        const result = getCustomFormat(unit, count);
        return result ? result + " ago" : "";
      },
    },
  };
  return formatDistanceToNowStrict(date, customOptions);
}

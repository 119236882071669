import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import * as ld from "launchdarkly-js-client-sdk";

interface LaunchDarklyFlag {
    [key: string]: unknown;
}

export interface FeatureFlag {
    loaded: boolean;
    initialized: boolean;
    flags: LaunchDarklyFlag;
    user?: ld.LDUser;
}

export function useFeatureFlags(): FeatureFlag {
    const flags: LaunchDarklyFlag = useFlags();
    const LDClient = useLDClient();

    const user = LDClient?.getUser();

    LDClient?.waitForInitialization();

    return {
        loaded: Boolean(LDClient),
        initialized: Boolean(user),
        flags,
        user,
    };
}

import { useEffect } from "react";
import chalk from "chalk";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useAppSelector } from "redux/hooks";
import { RootState } from "redux/store";

export function LaunchDarklyLogger() {
    const loginApi = useAppSelector((state: RootState) => state.login);
    const urlQuery = Object.fromEntries(
        new URLSearchParams(location.search.substring(1))
    );

    const { loaded, initialized, flags } = useFeatureFlags();

    useEffect(() => {
        const isLaunchDarklyReady = loaded && initialized;
        if (!isLaunchDarklyReady) {
            return;
        }
    }, [loaded, initialized, flags, urlQuery, loginApi]);

    return null;
}

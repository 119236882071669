import {
    EventCard as FDSEventCard,
    EventCardHeader,
    EventCardBody,
    EventCardMeta,
} from "@carrier-io/fds-react/patterns/EventCard";
import { MetadataItem } from "@carrier-io/fds-react/patterns/Metadata/MetadataItem";
import Metadata from "@carrier-io/fds-react/patterns/Metadata";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import NumbersIcon from "@mui/icons-material/Numbers";
import PlaceIcon from "@mui/icons-material/Place";
import { StatusType, Position } from "@carrier-io/fds-react/types/common";
import Typography from "@carrier-io/fds-react/Typography";
import { LastUpdated } from "components/LastUpdated";

export interface EventCardProps {
    id: string;
    image?: string | undefined;
    status?: StatusType;
    lastUpdated?: string;
    locationName?: string | undefined;
    address?: string | undefined;
    region?: string | undefined;
}

export const EventCard = (props: EventCardProps) => {
    return (
        <FDSEventCard
            onClick={() => {
                alert("Cooming Soon");
            }}
            sx={() => ({
                mb: "16px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#0000003B",
                p: "16px",
            })}
            preview={props.image}
            previewSx={{
                width: "200px",
                maxWidth: "135px",
            }}
            status={props.status}
            statusPosition={Position.Left}
        >
            <EventCardMeta>
                <Metadata>
                    <MetadataItem Icon={NumbersIcon}>{props.id}</MetadataItem>
                    <MetadataItem Icon={WatchLaterIcon}>
                        {props.lastUpdated}
                    </MetadataItem>
                    <MetadataItem Icon={PlaceIcon}>{props.region}</MetadataItem>
                </Metadata>
            </EventCardMeta>
            <EventCardBody>
                <Typography variant="h5">{props.locationName}</Typography>
                <Typography variant="body2">{props.address}</Typography>
            </EventCardBody>
        </FDSEventCard>
    );
};

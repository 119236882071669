import { useEffect, useState } from "react";

import SearchBox, {
    OnSearchBoxChange,
    SearchBoxProps,
} from "@carrier-io/fds-react/patterns/SearchBox";
import { useDebounce, useIsMounted } from "../../hooks";

interface SearchProps {
    onChange: (value: string) => void;
    placeholder?: string;
    sx?: SearchBoxProps["sx"];
}

export const DebouncedSearch = ({ onChange, placeholder, sx }: SearchProps) => {
    const [searchValue, setSearchValue] = useState("");
    const debouncedSearchValue = useDebounce<string>(searchValue);
    const isMounted = useIsMounted();

    useEffect(() => {
        if (isMounted) {
            // avoid callback on first render, when prop did not change
            onChange(debouncedSearchValue);
        }
    }, [debouncedSearchValue]);

    const handleChange = ({ text }: OnSearchBoxChange) => {
        setSearchValue(text);
    };

    return (
        <SearchBox
            multiple={false}
            sx={[
                {
                    backgroundColor: "background.paper",
                    "&.FdsSearchBox-sizeXsmall": {
                        minHeight: "36px",
                    },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            TextFieldProps={{
                placeholder,
                hideBackgroundColor: true,
                sx: {
                    "& .MuiFilledInput-root": {
                        borderColor: "addition.divider",

                        ".MuiFilledInput-input": {
                            fontSize: 12,
                        },
                        ".MuiFilledInput-input::placeholder": {
                            color: "action.active",
                            opacity: 1,
                        },
                    },
                },
            }}
            onChange={handleChange}
            size="xsmall"
        />
    );
};

import { useEffect, useMemo } from "react";
import { useIntercom } from "react-use-intercom";
import { IconButton } from "@mui/material";
import Box from "@carrier-io/fds-react/Box";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ReactComponent as ChatBotIcon } from "../../assets/icon/ChatbotIcon.svg";
import { setAuthBotActive } from "../../features/chatBot";
import { authBotActiveSelector } from "../../features/chatBot/redux/selectors";

import styles from "./styles";

export const getUser = () => {
    const stringUser = localStorage.getItem("user");
    const { email, name, id } = JSON.parse(
        stringUser ? stringUser : '{"name":""}'
    );

    const firstName = email.substring(0, email.indexOf("."));
    const lastName = email.substring(email.indexOf("."), email.length);

    const user = {
        key: id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        custom: {
            groups: ["Google"],
        },
    };
    return user;
};

export function ChatBot() {
    const { boot } = useIntercom();
    const dispatch = useAppDispatch();
    const authBotActive = useAppSelector(authBotActiveSelector);

    const botAvailable = useMemo(() => {
        return true;
    }, []);

    useEffect(() => {
        // Initialize the Support Chat Bot if not already initialized, but never in test
        if (botAvailable) {
            const email = getUser().email;
            const fName = getUser().firstName;
            const lName = getUser().lastName;
            // capitalize f and l names and join them in one string
            const userName = [fName, lName].join(" ");
            const userEmail = email ? email : "unknown@unknown.com";
            const botUserCreated_at = Date.now().toString();

            let userId = getUser().key;
            // Set the Intercom userId to match the LogRocket userId if Okta Id is present in user

            // initialize the chat bot if authbot is active and user's name has been retrieved
            if (authBotActive) {
                boot({
                    alignment: "left",
                    horizontalPadding: 30,
                    verticalPadding: 115,
                    name: userName,
                    email: userEmail,
                    createdAt: botUserCreated_at,
                    hideDefaultLauncher: true,
                    customLauncherSelector: "#tier1_drawer_chat_btn",
                    userId: userId,
                });
            }

            dispatch(setAuthBotActive(true));
        }
    }, [authBotActive, boot, dispatch]);

    return (
        <>
            {botAvailable && (
                <Box sx={styles.container} id="tier1_drawer_chat_btn">
                    <IconButton sx={styles.chatBtn} size="large">
                        <ChatBotIcon />
                    </IconButton>
                </Box>
            )}
        </>
    );
}

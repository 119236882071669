import { createSlice } from "@reduxjs/toolkit";
import { SiteState } from "../constants";

import { fetchSites } from "../redux/fetchSites";

export const initialState: SiteState = {
    pending: false,
    sites: [],
    lastUpdated: 0,
    error: "",
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        resetAuth: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSites.pending, (state) => {
            state.pending = true;
        });
        builder.addCase(fetchSites.fulfilled, (state, { payload }) => {
            state.pending = false;
            state.lastUpdated = payload.lastUpdated;
            state.sites = payload.sites;
            state.error = "";
            console.log("builder payload is ", payload);
        });
        builder.addCase(fetchSites.rejected, (state, { payload }) => {
            state.pending = false;
            state.error = payload as string;
        });
    },
});

const { reducer } = dashboardSlice;
export const { resetAuth } = dashboardSlice.actions;

export default reducer;

import { useHistory, useRouteMatch } from "react-router-dom";

import SvgIcon from "@carrier-io/fds-react/SvgIcon";
import {
    ActiveButtonType,
    ClickNavItem,
    Navbar as FDSNavbar,
    NavigationItem,
} from "@carrier-io/fds-react/patterns/Navbar";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ImageIcon from "@mui/icons-material/Image";
import {
    COMING_SOON_PATH,
    DASHBOARD_PATH,
} from "../../features/App/RoutesConstant";
import { useCheckFeatureFlagOn } from "../../hooks/useCheckFeatureFlagOn";
import { ChatBot } from "components/ChatBot";

export const Navbar = () => {
    const showImageFeature = useCheckFeatureFlagOn("deployFeatureImage");
    const regularListItems: NavigationItem[] = [
        {
            label: "Dashboard",
            id: "dashboard",
            icon: (
                <SvgIcon>
                    <ApartmentIcon />
                </SvgIcon>
            ),
            path: DASHBOARD_PATH,
        },
    ];

    if (showImageFeature) {
        regularListItems.push({
            label: "Image",
            id: "image",
            icon: (
                <SvgIcon>
                    <ImageIcon />
                </SvgIcon>
            ),
            path: COMING_SOON_PATH,
        });
    }
    const history = useHistory();
    const match = useRouteMatch({ path: "/:page" });
    const currentPage = match?.url ?? "";

    const handleNavigationClick: ClickNavItem = ({ path }) => {
        if (path) {
            history.push(path);
        }
    };

    return (
        <FDSNavbar
            sx={(theme) => ({
                overflow: "hidden",
                position: "fixed",
                top: 0,
                marginTop: theme.sizes.appBarHeight,
                height: `calc(100% - ${theme.sizes.appBarHeight})`,
                borderRight: "0.5px solid #BCC3C9",
            })}
        >
            <FDSNavbar.Navigation
                currentPathname={currentPage}
                items={regularListItems}
                activeButtonType={ActiveButtonType.Basic}
                onClick={handleNavigationClick}
            />
            <FDSNavbar.Footer showCarrierLogo>
                <ChatBot />
            </FDSNavbar.Footer>
        </FDSNavbar>
    );
};

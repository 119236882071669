import Box from "@carrier-io/fds-react/Box";
import { DebouncedSearch } from "components/DebouncedSearch";
import { EventCard } from "components/EventCard/EventCard";
import { LastUpdated } from "components/LastUpdated";
import { NoDataValueWrapper } from "components/NoDataValueWrapper";
import { PageContentWrapper } from "components/PageContentWrapper";
import { MobilePageContentWrapper } from "components/MobilePageContentWrapper";

import { PageHeaderLayout } from "components/PageHeaderLayout";
import { PageSubHeader } from "components/PageSubHeader";
import Tabs from "@carrier-io/fds-react/Tabs";
import Tab from "@carrier-io/fds-react/Tab";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { RootState } from "redux/store";
import { SyntheticEvent, useEffect, useState } from "react";
import { fetchSites } from "./redux/fetchSites";
import { StatusType } from "@carrier-io/fds-react/types/common";
import { SiteList } from "./types";
import { SiteTabs } from "./constants";
import { selectSiteListCount } from "./redux/selectors";
import { AlarmList } from "./components/AlarmList/AlarmList";
import { useCheckFeatureFlagOn } from "hooks/useCheckFeatureFlagOn";
import BottomNavigation from "@carrier-io/fds-react/BottomNavigation";
import BottomNavigationAction from "@carrier-io/fds-react/BottomNavigationAction";
import BusinessIcon from "@mui/icons-material/Business";
import AddAlertIcon from "@mui/icons-material/AddAlert";

import {
    BrowserView as BrowserViewOrig,
    MobileView as MobileViewOrig,
} from "react-device-detect";
import { Typography } from "@carrier-io/fds-react";
import { PageFooterLayout } from "components/PageFooterLayout";
import { AlarmListMobile } from "./components/AlarmListMobile/AlarmListMobile";
const BrowserView: any = BrowserViewOrig;
const MobileView: any = MobileViewOrig;

const matchAlertLevel = (alert: string) => {
    switch (alert) {
        case "error":
            return StatusType.Error;
            break;
        case "info":
            return StatusType.Info;
            break;
        case "primary":
            return StatusType.Primary;
            break;
        case "secondary":
            return StatusType.Secondary;
            break;
        case "success":
            return StatusType.Success;
            break;
        case "warning":
            return StatusType.Warning;
            break;
        default:
            return StatusType.Primary;
            break;
    }
};
export const Dashboard = () => {
    const dispatch = useAppDispatch();
    const sites = useAppSelector((state: RootState) => state.dashboard);
    const [filteredSites, setFilteredSites] = useState<SiteList>([]);
    const sitesListTabsCount = useAppSelector(selectSiteListCount);
    const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(0);
    const [tab, setTab] = useState<SiteTabs>(SiteTabs.All);
    const [alarmList, setAlarmList] = useState<Alarm[]>([]);
    const showImageFeature = useCheckFeatureFlagOn("changeLanguage");
    const [bottomNav, setBottomNav] = useState(0);

    const handleSearch = (value: string) => {
        if (tab == SiteTabs.All) {
            setFilteredSites(
                sites.sites.filter(
                    (site: Site) =>
                        new RegExp(value, "i").test(site.name) ||
                        new RegExp(value, "i").test(site.id) ||
                        new RegExp(value, "i").test(site.address)
                )
            );
        } else {
            setFilteredSites(
                sites.sites.filter(
                    (site: Site) =>
                        site.status == tab &&
                        filteredSites.filter(
                            (site: Site) =>
                                new RegExp(value, "i").test(site.name) ||
                                new RegExp(value, "i").test(site.id) ||
                                new RegExp(value, "i").test(site.address)
                        )
                )
            );
        }
    };

    const handleTabChange = (_e: SyntheticEvent, value: SiteTabs) => {
        setTab(value);
    };

    const updateList = () => {
        if (tab == SiteTabs.All) {
            setFilteredSites(sites.sites);
        } else {
            setFilteredSites(
                sites.sites.filter((site: Site) => site.status == tab)
            );
        }
        setLastUpdatedTimestamp(sites.lastUpdated);
        let listOfAlarms: any = [];
        sites.sites.forEach((site) => {
            site.chillers?.forEach((chillers) => {
                if (chillers.alarms)
                    listOfAlarms = [...listOfAlarms, ...chillers.alarms];
            });
        });
        setAlarmList(listOfAlarms);
    };

    useEffect(() => {
        dispatch(
            fetchSites({ search: "", changeLanguageFlag: showImageFeature })
        );
    }, [showImageFeature]);

    useEffect(() => {
        updateList();
    }, [tab, sites.sites]);

    return (
        <>
            <BrowserView>
                <PageContentWrapper>
                    <PageHeaderLayout
                        sx={{ marginBottom: "30px" }}
                        subHeader={
                            <PageSubHeader
                                items={[
                                    lastUpdatedTimestamp ? (
                                        <LastUpdated
                                            showLabel
                                            timestamp={lastUpdatedTimestamp}
                                        />
                                    ) : (
                                        <NoDataValueWrapper
                                            withTooltip={false}
                                        />
                                    ),
                                ]}
                                pending={sites.pending}
                            />
                        }
                        pageTitle={showImageFeature ? "仪表板" : "Dashboard"}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            maxHeight: `calc(100vh - 300px)`,
                        }}
                    >
                        <Box
                            sx={{
                                flex: 9,
                            }}
                        >
                            <Tabs
                                value={tab}
                                onChange={handleTabChange}
                                sx={(theme) => ({
                                    borderBottom: `1px solid ${theme.palette.addition?.divider}`,
                                })}
                            >
                                <Tab
                                    label={
                                        showImageFeature
                                            ? "全部(" +
                                              sitesListTabsCount["All"] +
                                              ")"
                                            : "All(" +
                                              sitesListTabsCount["All"] +
                                              ")"
                                    }
                                    value={SiteTabs.All}
                                />
                                <Tab
                                    label={
                                        showImageFeature
                                            ? "连接的(" +
                                              sitesListTabsCount["Connected"] +
                                              ")"
                                            : "Connected(" +
                                              sitesListTabsCount["Connected"] +
                                              ")"
                                    }
                                    value={SiteTabs.Connected}
                                />
                                <Tab
                                    label={
                                        showImageFeature
                                            ? "离线(" +
                                              sitesListTabsCount["Offline"] +
                                              ")"
                                            : "Offline(" +
                                              sitesListTabsCount["Offline"] +
                                              ")"
                                    }
                                    value={SiteTabs.Offline}
                                />
                            </Tabs>
                            <DebouncedSearch
                                onChange={handleSearch}
                                sx={{
                                    flexGrow: 1,
                                    maxWidth: "340px",
                                    my: "16px",
                                }}
                                placeholder="Search sites"
                            />
                            <Box
                                sx={{
                                    height: "100%",
                                    overflowY: "scroll",
                                    flex: 3,
                                    pr: "4px",
                                }}
                            >
                                {filteredSites.map((site) => {
                                    const lastUpdated =
                                        site.lastUpdated && site.timezone
                                            ? new Date(
                                                  site.lastUpdated
                                              ).toLocaleTimeString("en-US", {
                                                  timeZone: site.timezone,
                                              })
                                            : new Date(0).toLocaleTimeString();

                                    return (
                                        <EventCard
                                            key={site.id}
                                            id={site.id}
                                            image={site?.image}
                                            status={
                                                site.alertLevel
                                                    ? matchAlertLevel(
                                                          site.alertLevel
                                                      )
                                                    : StatusType.Primary
                                            }
                                            locationName={site?.name}
                                            address={site?.address}
                                            region={site.region}
                                            lastUpdated={lastUpdated.toString()}
                                        />
                                    );
                                })}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                height: `calc(100vh - 190px )`,
                                ml: "30px",
                                flexGrow: 1,
                                mr: "16px",
                                my: "8px",
                                pt: "42px",
                            }}
                        >
                            <AlarmList alarms={alarmList} />
                        </Box>
                    </Box>
                </PageContentWrapper>
            </BrowserView>
            <MobileView>
                <MobilePageContentWrapper>
                    <PageHeaderLayout
                        subHeader={
                            <PageSubHeader
                                items={[
                                    lastUpdatedTimestamp ? (
                                        <LastUpdated
                                            showLabel
                                            timestamp={lastUpdatedTimestamp}
                                        />
                                    ) : (
                                        <NoDataValueWrapper
                                            withTooltip={false}
                                        />
                                    ),
                                ]}
                                pending={sites.pending}
                            />
                        }
                        pageTitle={showImageFeature ? "仪表板" : "Dashboard"}
                    />
                    {bottomNav == 0 && (
                        <Box>
                            <Tabs
                                value={tab}
                                onChange={handleTabChange}
                                sx={(theme) => ({
                                    borderBottom: `1px solid ${theme.palette.addition?.divider}`,
                                })}
                            >
                                <Tab
                                    label={
                                        showImageFeature
                                            ? "全部(" +
                                              sitesListTabsCount["All"] +
                                              ")"
                                            : "All(" +
                                              sitesListTabsCount["All"] +
                                              ")"
                                    }
                                    value={SiteTabs.All}
                                />
                                <Tab
                                    label={
                                        showImageFeature
                                            ? "连接的(" +
                                              sitesListTabsCount["Connected"] +
                                              ")"
                                            : "Connected(" +
                                              sitesListTabsCount["Connected"] +
                                              ")"
                                    }
                                    value={SiteTabs.Connected}
                                />
                                <Tab
                                    label={
                                        showImageFeature
                                            ? "离线(" +
                                              sitesListTabsCount["Offline"] +
                                              ")"
                                            : "Offline(" +
                                              sitesListTabsCount["Offline"] +
                                              ")"
                                    }
                                    value={SiteTabs.Offline}
                                />
                            </Tabs>
                            <DebouncedSearch
                                onChange={handleSearch}
                                sx={{
                                    flexGrow: 1,
                                    maxWidth: "321px",
                                    my: "16px",
                                }}
                                placeholder="Search sites"
                            />
                            <Box
                                sx={{
                                    height: "100vh",
                                    overflowY: "scroll",
                                    pb: "350px",
                                }}
                            >
                                {filteredSites.map((site) => {
                                    const lastUpdated =
                                        site.lastUpdated && site.timezone
                                            ? new Date(
                                                  site.lastUpdated
                                              ).toLocaleTimeString("en-US", {
                                                  timeZone: site.timezone,
                                              })
                                            : new Date(0).toLocaleTimeString();

                                    return (
                                        <EventCard
                                            key={site.id}
                                            id={site.id}
                                            status={
                                                site.alertLevel
                                                    ? matchAlertLevel(
                                                          site.alertLevel
                                                      )
                                                    : StatusType.Primary
                                            }
                                            locationName={site?.name}
                                            address={site?.address}
                                            region={site.region}
                                            lastUpdated={lastUpdated.toString()}
                                        />
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                    {bottomNav == 1 && (
                        <Box>
                            <AlarmListMobile alarms={alarmList} />
                        </Box>
                    )}
                    <PageFooterLayout>
                        <BottomNavigation
                            onChange={(_, val) => {
                                setBottomNav(val);
                            }}
                            value={bottomNav}
                            sx={{ width: "85%" }}
                        >
                            <BottomNavigationAction
                                icon={<BusinessIcon />}
                                sx={{ flexGrow: 1 }}
                            />
                            <BottomNavigationAction
                                icon={<AddAlertIcon />}
                                sx={{ flexGrow: 1 }}
                            />
                        </BottomNavigation>
                    </PageFooterLayout>
                </MobilePageContentWrapper>
            </MobileView>
        </>
    );
};

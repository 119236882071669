import { RootState } from "redux/store";
import { SiteTabs } from "../constants";

export const selectSiteListCount = (state: RootState) => {
    if (!state.dashboard.sites) {
        return {
            [SiteTabs.All]: 0,
            [SiteTabs.Connected]: 0,
            [SiteTabs.Offline]: 0,
        };
    }

    return {
        [SiteTabs.All]: state.dashboard.sites.length,
        [SiteTabs.Connected]: state.dashboard.sites.filter(
            (site: Site) => site.status == "Connected"
        ).length,
        [SiteTabs.Offline]: state.dashboard.sites.filter(
            (site: Site) => site.status == "Offline"
        ).length,
    };
};

import { FC } from "react";
import Box from "@carrier-io/fds-react/Box";

export const PageContentWrapper: FC = ({ children }) => (
    <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "0px 23px",
        }}
    >
        {children}
    </Box>
);

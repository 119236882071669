import React from "react";
import Box from "@carrier-io/fds-react/Box";
import EchartContainer, {
    EChartsOption,
} from "@carrier-io/fds-react/EchartContainer";
import { useEffect, useState } from "react";
import { Typography } from "@carrier-io/fds-react";

export interface TotalGraphProps {
    alarms: Alarm[];
    totals: {
        critical: number;
        highRisk: number;
        warning: number;
        nonOptimal: number;
        other: number;
    };
}

const echartOptions: EChartsOption = {};

export const TotalGraphMobile = (props: TotalGraphProps) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
        const critpercent = Math.round(
            (props.totals.critical / props.alarms.length) * 100
        );
        if (critpercent == 0 || props.alarms.length == 0) return;

        echartOptions.color = [
            "#F44336",
            "#F44336",
            "#ffc107",
            "#00000099",
            "#00000099",
        ];
        echartOptions.graphic = {
            elements: [
                {
                    type: "text",
                    left: "center",
                    top: "middle",
                    z: 999,
                    style: {
                        text: "  " + critpercent + "%\nCritical",
                        fontSize: 24,
                    },
                },
            ],
        };
        echartOptions["series"] = [
            {
                name: "Access From",
                type: "pie",
                radius: ["64%", "70%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    color: "#000",
                    fontSize: "80",
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: "30",
                        fontWeight: "bold",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: [
                    { name: "critical", value: props.totals.critical },
                    { name: "highRisk", value: props.totals.highRisk },
                    { name: "warning", value: props.totals.warning },
                    { name: "nonOptimal", value: props.totals.nonOptimal },
                    { name: "other", value: props.totals.other },
                ],
            },
        ];
        setLoaded(true);
    }, [props.alarms]);

    return (
        <Box>
            <Typography variant="subtitle1" sx={{ pt: "22px" }}>
                Building Alarms
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    div: {
                        width: "182px",
                    },
                }}
            >
                {loaded && (
                    <EchartContainer
                        chartOptions={echartOptions}
                        containerHeight="182px"
                    />
                )}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    ml: "6px",
                    pb: "16px",
                }}
            >
                <Box sx={{ flexGrow: 1, width: "80px" }}>
                    <Typography variant="h5">{props.alarms.length}</Typography>
                    <Typography variant="subtitle2" color="text.primary">
                        Total Alerts
                    </Typography>
                </Box>
                <Box
                    sx={{ flexGrow: 1, width: "68px", display: "inline-block" }}
                >
                    <Typography variant="h5" color="error.main">
                        {props.totals.critical + props.totals.highRisk}
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary">
                        Critical
                    </Typography>
                </Box>
                <Box
                    sx={{ flexGrow: 1, width: "68px", display: "inline-block" }}
                >
                    <Typography variant="h5" color="warning.main">
                        {props.totals.warning}
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary">
                        Warning
                    </Typography>
                </Box>
                <Box
                    sx={{ flexGrow: 1, width: "68px", display: "inline-block" }}
                >
                    <Typography variant="h5" color="text.disabled">
                        {props.totals.other}
                    </Typography>
                    <Typography variant="subtitle2" color="text.primary">
                        Other
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

import { FC } from "react";
import Box from "@carrier-io/fds-react/Box";

export const MobilePageContentWrapper: FC = ({ children }) => (
    <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh)",
            padding: "8px 8px",
            maxWidth: "475px",
            overflowY: "hidden",
        }}
    >
        {children}
    </Box>
);

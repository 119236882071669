import React, { ReactNode } from "react";
import Box from "@carrier-io/fds-react/Box";
import Typography from "@carrier-io/fds-react/Typography";
import { SxProps, Theme } from "@carrier-io/fds-react/styles";

interface HeaderLayoutProps {
    breadcrumbs?: ReactNode;
    pageTitle: string;
    pageSubTitle?: string;
    subHeader?: ReactNode;
    sx?: SxProps<Theme>;
}

export const PageHeaderLayout = ({
    breadcrumbs,
    pageTitle,
    pageSubTitle,
    subHeader,
    sx = [],
}: HeaderLayoutProps) => {
    return (
        <Box
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
            display="grid"
            gridTemplateRows="21px"
            gridAutoRows="auto"
        >
            <Box>{breadcrumbs}</Box>
            <Box>
                <Typography variant="h4" sx={{ marginBottom: "5px" }}>
                    {pageTitle}
                </Typography>
            </Box>
            {pageSubTitle && (
                <Box>
                    <Typography
                        variant="body2"
                        sx={(theme) => ({
                            marginBottom: "5px",
                            color: `${theme.palette.text.secondary}`,
                        })}
                    >
                        {pageSubTitle}
                    </Typography>
                </Box>
            )}
            <Box>{subHeader}</Box>
        </Box>
    );
};

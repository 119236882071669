import { FC } from "react";
import Box from "@carrier-io/fds-react/Box";

export const PageFooterLayout: FC = ({ children }) => (
    <Box
        sx={{
            position: "fixed",
            bottom: 0,
            width: "calc(100% - 56px)",
            ml: "-8px",
            display: "flex",
            zIndex: 1000,
            borderTop: "0.5px solid #BCC3C9",
            borderLeft: "0.5px solid #BCC3C9",
            backgroundColor: "#FFF",
        }}
    >
        {children}
    </Box>
);

import React, { useState, useCallback, useEffect } from "react";
import Box from "@carrier-io/fds-react/Box";
import Copyright from "@carrier-io/fds-react/patterns/Copyright/Copyright";
import ForgotPassword from "@carrier-io/fds-react/patterns/SignIn/ForgotPassword";
import Logo from "@carrier-io/fds-react/patterns/Logo/Logo";
import TermsOfService from "@carrier-io/fds-react/patterns/SignIn/TermsOfService";
import Typography from "@carrier-io/fds-react/Typography";
import SignIn from "@carrier-io/fds-react/patterns/SignIn/SignIn";
import { SignInFooter } from "@carrier-io/fds-react/patterns/SignIn/SignInFooter";
import {
    SingInFormErrors,
    SignInForm,
} from "@carrier-io/fds-react/patterns/SignIn/SignInForm";
import { isEmailValid } from "@carrier-io/fds-react/patterns/SignIn/utils/validator";
import Link from "@carrier-io/fds-react/Link";
import ProjectLogo from "../../assets/icon/ScienceFairLogin.svg";
import CarrierLogo from "../../assets/images/carrier-logo.png";
import Alert from "@carrier-io/fds-react/Alert";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../..//redux/hooks";
import { fetchLogin } from "./redux/fetchLogin";
import { RootState } from "../../redux/store";
import { ACCESS_TOKEN } from "./constants";

const DEFAULT_ERRORS: SingInFormErrors = {
    email: null,
    password: null,
};

export const Login = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [currentEmail, setCurrentEmail] = useState<string>("");
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [staySignIn, setStaySignIn] = useState<boolean>(false);
    const [termsOfServiceAccepted, setTermsOfServiceAccepted] =
        useState<boolean>(false);
    const [acknowledge, setAcknowledge] = useState<boolean>(true);
    const [alertText, setAlertText] = useState<string | null>(null);
    const [formErrors, setFormErrors] = useState<SingInFormErrors>({
        ...DEFAULT_ERRORS,
    });
    const didLogin = useAppSelector((state: RootState) => state.login);

    const resetErrors = useCallback(() => {
        setAlertText(null);
        setFormErrors({ ...DEFAULT_ERRORS });
        localStorage.clear();
    }, []);

    const handleFormSubmit = () => {
        const { isValid, errorMessage } = isEmailValid(currentEmail);

        resetErrors();

        if (!isValid) {
            setFormErrors({ ...DEFAULT_ERRORS, email: errorMessage });
            return;
        }

        if (!acknowledge) {
            setAlertText("Please Accepts the termsOfService.");
            return;
        }

        if (!currentPassword) {
            setFormErrors({ ...DEFAULT_ERRORS, password: "Password error" });
            return;
        }

        dispatch(
            fetchLogin({ username: currentEmail, password: currentPassword })
        );
    };

    useEffect(() => {
        if (didLogin.errorMessage) {
            setAlertText(didLogin.errorMessage);
        }
        if (didLogin.isLoggedIn && localStorage.getItem(ACCESS_TOKEN) != null) {
            setFormErrors({
                ...DEFAULT_ERRORS,
            });
            history.push("/Dashboard");
        }
    }, [didLogin]);

    return (
        <>
            {!!alertText && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Alert
                        closeText="Close"
                        color="error"
                        severity="error"
                        variant="filled"
                        sx={{
                            width: "480px",
                            height: "64px",
                            borderRadius: "8px",
                            margin: "30px 0 0 0",
                        }}
                    >
                        {alertText}
                    </Alert>
                </Box>
            )}
            <SignIn
                footer={
                    <SignInFooter
                        left={
                            <TermsOfService
                                acknowledge={termsOfServiceAccepted}
                                onAcknowledge={(value: boolean) => {
                                    setAcknowledge(value);
                                }}
                                onApprove={() => {
                                    setTermsOfServiceAccepted(true);
                                }}
                                onReject={() => {
                                    setTermsOfServiceAccepted(false);
                                }}
                                sxEntryLabel={{ fontSize: "13px" }}
                                tabsConfiguration={[
                                    {
                                        content:
                                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ip",
                                        id: "1",
                                        linkToPDF:
                                            "https://images.carriercms.com/image/upload/v1617310773/carrier-corp/healthy-cold-chain/Refrigeration-Lynx-Terms-of-Use-0321-English.pdf",
                                        title: "Terms of Use",
                                    },
                                    {
                                        content:
                                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ip",
                                        id: "2",
                                        linkToPDF:
                                            "https://images.carriercms.com/image/upload/v1617310773/carrier-corp/healthy-cold-chain/Refrigeration-Lynx-Terms-of-Use-0321-English.pdf",
                                        title: "Privacy Policy",
                                    },
                                ]}
                            />
                        }
                        middle={
                            <Logo
                                alt="Carrier's logo"
                                href="https://www.carrier.com/"
                                src={CarrierLogo}
                            />
                        }
                        right={<Copyright text="Carrier" />}
                    />
                }
            >
                <Logo
                    alt="logo"
                    src={ProjectLogo}
                    sx={{
                        marginBottom: "32px",
                        marginTop: "64px",
                        height: "73px",
                        justifyContent: "center",
                    }}
                />
                <Typography
                    sx={{
                        marginBottom: "16px",
                    }}
                    variant="h5"
                >
                    Welcome to TruffleOil
                </Typography>
                <Typography
                    color="secondary"
                    sx={{
                        marginBottom: "24px",
                    }}
                    variant="body2"
                >
                    Sign in with your user email & password
                </Typography>
                <SignInForm
                    EmailInputProps={{
                        value: currentEmail,
                        onChange: (e) =>
                            setCurrentEmail(e.currentTarget.value.trim()),
                        onKeyDown: (e) => {
                            if (e.key == "Enter") {
                                handleFormSubmit();
                            }
                        },
                    }}
                    PasswordInputProps={{
                        value: currentPassword,
                        onChange: (e) =>
                            setCurrentPassword(e.currentTarget.value.trim()),
                        onKeyDown: (e) => {
                            if (e.key == "Enter") {
                                handleFormSubmit();
                            }
                        },
                    }}
                    StaySignedInProps={{
                        checkboxProps: {
                            checked: staySignIn,
                            onChange: (e) =>
                                setStaySignIn(e.currentTarget.checked),
                        },
                    }}
                    SubmitButtonProps={{
                        onClick: handleFormSubmit,
                    }}
                    customComponent={
                        <Typography align="center" mt={2} variant="subtitle2">
                            By continuing you agree to our{" "}
                            <Link
                                href="https://abound.carrier.com/privacy"
                                rel="noreferrer"
                                target="_blank"
                                underline="hover"
                            >
                                Privacy Policy
                            </Link>
                        </Typography>
                    }
                    errors={formErrors}
                />
            </SignIn>
        </>
    );
};

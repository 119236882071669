import { FC, PropsWithChildren } from "react";
import Box from "@carrier-io/fds-react/Box";
import { AppBarTopHeader } from "../../../components/AppBarTopHeader";
import { Navbar } from "../../../components/Navbar";
import { Route } from "react-router-dom";
import { CustomRouteProps } from "features/App/types";

const DashboardLayout: FC<PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <Box sx={{ minHeight: "100vh", display: "flex" }}>
            <AppBarTopHeader />
            <Navbar />
            <Box
                component="main"
                sx={(theme) => ({
                    flexGrow: 1,
                    marginLeft: theme.sizes.navbarWidth,
                    marginTop: theme.sizes.appBarHeight,
                })}
            >
                {children}
            </Box>
        </Box>
    );
};

const DashboardLayoutRoute: React.FC<CustomRouteProps> = ({
    component: Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <DashboardLayout>
                    <Component {...props} />
                </DashboardLayout>
            )}
        />
    );
};
export default DashboardLayoutRoute;

import {
    EventCard as FDSEventCard,
    EventCardHeader,
    EventCardBody,
    EventCardMeta,
} from "@carrier-io/fds-react/patterns/EventCard";
import { MetadataItem } from "@carrier-io/fds-react/patterns/Metadata/MetadataItem";
import Metadata from "@carrier-io/fds-react/patterns/Metadata";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import NumbersIcon from "@mui/icons-material/Numbers";
import PlaceIcon from "@mui/icons-material/Place";
import { StatusType, Position } from "@carrier-io/fds-react/types/common";
import Typography from "@carrier-io/fds-react/Typography";
import { LastUpdated } from "components/LastUpdated";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Grid from "@carrier-io/fds-react/Grid";

export interface EventCardProps {
    severity?: string;
    name?: string;
    description?: string;
    serialNumber?: string;
    createdAt?: string;
    metaName?: string;
    load?: number;
}

export const AlarmSeverityToStatusType = (alarm: any) => {
    switch (alarm) {
        case "Critical":
            return StatusType.Error;
        case "High Risk":
            return StatusType.Error;
        case "Warning":
            return StatusType.Warning;
        case "Non-Optimal":
            return StatusType.Info;
        default:
            return StatusType.Secondary;
    }
};
export const AlarmSeverityToHexColor = (alarm: any) => {
    switch (alarm) {
        case "Critical":
            return "#F44336";
        case "High Risk":
            return "#F44336";
        case "Warning":
            return "#ffc107";
        case "Non-Optimal":
            return "#2196F3";
        default:
            return "#424242";
    }
};
export const AlarmLoadToLoadString = (load?: number) => {
    if (!load) return "";
    if (load > 70) return "High " + load + "%";
    if (load > 40) return "Medium " + load + "%";
    return "Load " + load + "%";
};

const FindTimeDetlta = (created: string) => {
    if (!isNaN(new Date(created).getTime())) {
        const millis = Date.now() - new Date(created).getTime();
        const minutes = Math.floor(millis / 60000);
        const seconds = Math.floor((millis % 60000) / 1000);

        return minutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s";
    } else {
        return "0m";
    }
};
export const AlertEventCardMobile = (props: EventCardProps) => {
    return (
        <FDSEventCard
            onClick={() => {
                alert("Coming Soon");
            }}
            sx={{
                my: "2px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#0000003B",
            }}
            status={AlarmSeverityToStatusType(props.severity)}
            statusPosition={Position.Left}
        >
            <EventCardHeader
                bgIcon={AlarmSeverityToHexColor(props.severity)}
                title={props.name}
            ></EventCardHeader>
            <EventCardBody sx={{ mt: "8px" }}>
                <Grid container spacing={2} columns={4}>
                    <Grid xs={2} item>
                        <Typography variant="body2">
                            {props.description}
                        </Typography>
                    </Grid>
                    <Grid xs={2} item>
                        <Typography
                            sx={(theme) => ({
                                backgroundColor: theme.palette.verge?.primary,
                                borderRadius: "4px",
                            })}
                            variant="body3"
                        >
                            {AlarmLoadToLoadString(props.load)}
                        </Typography>
                    </Grid>
                </Grid>
            </EventCardBody>
            <EventCardMeta sx={{ mt: "8px!important" }}>
                <Metadata>
                    <MetadataItem>{props.serialNumber}</MetadataItem>
                    <MetadataItem Icon={WatchLaterIcon}>
                        {FindTimeDetlta(props.createdAt || "")}
                    </MetadataItem>
                    <MetadataItem Icon={PlaceIcon}>
                        {props.metaName}
                    </MetadataItem>
                </Metadata>
            </EventCardMeta>
        </FDSEventCard>
    );
};

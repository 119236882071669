import Alert from "@carrier-io/fds-react/Alert";
import AlertTitle from "@carrier-io/fds-react/AlertTitle";
import Box from "@carrier-io/fds-react/Box";
import Typography from "@carrier-io/fds-react/Typography";
import { MetadataItem } from "@carrier-io/fds-react/patterns/Metadata/MetadataItem";
import Metadata from "@carrier-io/fds-react/patterns/Metadata";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { AlertEventCard } from "components/EventCard/AlertEventCard";
import Paper from "@carrier-io/fds-react/Paper";
import { useCheckFeatureFlagOn } from "hooks/useCheckFeatureFlagOn";
import { TotalGraphMobile } from "../TotalGraphMobile/TotalGraphMobile";
import { useEffect, useState } from "react";
import { AlertEventCardMobile } from "components/EventCard/AlertEventCardMobile";

export interface AlarmListProps {
    alarms: Alarm[];
}

const FindCriticalAlarmPercentage = (alarms: Alarm[]) => {
    const totals = {
        critical: 0,
        highRisk: 0,
        warning: 0,
        nonOptimal: 0,
        other: 0,
    };

    alarms.forEach((alarms) => {
        if (alarms.severity == "Critical")
            totals.critical = totals.critical + 1;
        else if (alarms.severity == "High Risk")
            totals.highRisk = totals.highRisk + 1;
        else if (alarms.severity == "Warning")
            totals.warning = totals.warning + 1;
        else if (alarms.severity == "Non-Optimal")
            totals.nonOptimal = totals.nonOptimal + 1;
        else totals.other = totals.other + 1;
    });
    return totals;
};

export const AlarmListMobile = (props: AlarmListProps) => {
    const upgradeDashboard = useCheckFeatureFlagOn("upgradeDashboard");
    let boxListSX = upgradeDashboard
        ? {
              height: "100%",
              overflowY: "scroll",
              pb: "350px",
          }
        : {
              height: "100%",
              overflowY: "scroll",
              pb: "100px",
          };

    useEffect(() => {
        boxListSX = upgradeDashboard
            ? {
                  height: "100%",
                  overflowY: "scroll",
                  pb: "350px",
              }
            : {
                  height: "100%",
                  overflowY: "scroll",
                  pb: "100px",
              };
    }, [upgradeDashboard]);

    return (
        <Paper
            sx={(theme) => ({
                height: "calc(100vh - 148px)",
                overflowY: "hidden",
                backgroundColor: theme.palette.background.paper,
                px: "5px",
                mt: "16px",
            })}
        >
            {upgradeDashboard ? (
                <TotalGraphMobile
                    alarms={props.alarms}
                    totals={FindCriticalAlarmPercentage(props.alarms)}
                />
            ) : (
                <Typography variant="subtitle1" sx={{ py: "10px" }}>
                    Building Alarms
                </Typography>
            )}
            <Box sx={boxListSX}>
                {props.alarms.map((alarm, index) => {
                    return (
                        <AlertEventCardMobile
                            key={index}
                            severity={alarm.severity}
                            name={alarm.severity}
                            description={alarm.description}
                            serialNumber={alarm.serialNumber}
                            createdAt={alarm.createdAt}
                            metaName={alarm.name}
                            load={alarm.load}
                        />
                    );
                })}
            </Box>
        </Paper>
    );
};

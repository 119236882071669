import React, { useEffect, useState } from "react";
import Typography from "@carrier-io/fds-react/Typography";
import Box, { BoxProps } from "@carrier-io/fds-react/Box";

import { getCustomFormattedDistanceToNow } from "../../helpers/date";
import { ReactComponent as Attention } from "../../assets/icon/Attention.svg";
import { ReactComponent as LastUpdatedIcon } from "../../assets/icon/LastUpdated.svg";
import { useCheckFeatureFlagOn } from "hooks/useCheckFeatureFlagOn";

const INTERVAL = 60 * 1000; // 1m

interface LastUpdatedProps extends BoxProps {
    timestamp: number;
    showLabel?: boolean;
}

export const LastUpdated = ({
    timestamp,
    showLabel = false,
    sx,
    ...rest
}: LastUpdatedProps) => {
    const [distanceFromNow, setDistanceFromNow] = useState("");
    const showImageFeature = useCheckFeatureFlagOn("changeLanguage");

    useEffect(() => {
        updateDistanceFromNow();
    }, [timestamp]);

    useEffect(() => {
        const timerId = setInterval(updateDistanceFromNow, INTERVAL);
        return () => {
            clearInterval(timerId);
        };
    }, [timestamp]);

    const updateDistanceFromNow = () =>
        setDistanceFromNow(getCustomFormattedDistanceToNow(timestamp));

    return (
        <Box
            sx={[
                { whiteSpace: "nowrap", display: "flex", alignItems: "center" },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        >
            {showLabel && (
                <Typography
                    variant="body2"
                    component="span"
                    color="text.secondary"
                    sx={{ marginRight: "8px" }}
                >
                    {showImageFeature ? "最近更新时间" : "Last updated:"}
                </Typography>
            )}
            <Box
                component="span"
                sx={{
                    marginRight: "5px",
                    width: "14px",
                    height: "14px",
                    display: "inline-flex",
                    svg: {
                        width: "100%",
                        height: "100%",
                    },
                }}
            >
                <LastUpdatedIcon />
            </Box>
            <Typography variant="body2" color="text.secondary">
                {distanceFromNow}
            </Typography>
        </Box>
    );
};

import React from "react";
import Box from "@carrier-io/fds-react/Box";
import CircularProgress from "@carrier-io/fds-react/CircularProgress";

export const Loader = () => (
  <Box
    width="100%"
    height="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    data-testid="fallback-loader"
  >
    <CircularProgress
      size={40}
      thickness={3}
      value={0}
      variant="indeterminate"
    />
  </Box>
);

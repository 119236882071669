import React, { lazy } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import DashboardLayoutRoute from "../layout/components/DashboardLayout";

import { LOGIN_PATH, DASHBOARD_PATH, COMING_SOON_PATH } from "./RoutesConstant";

const Login = lazy(() => import("../login"));
const ComingSoon = lazy(() => import("../comingsoon"));
const Dashboard = lazy(() => import("../dashboard"));

function Routes() {
    return (
        <Switch>
            <Route path={LOGIN_PATH} component={Login} />
            <DashboardLayoutRoute path={DASHBOARD_PATH} component={Dashboard} />
            <DashboardLayoutRoute
                path={COMING_SOON_PATH}
                component={ComingSoon}
            />
            <Route path="*" component={Login} />
        </Switch>
    );
}

export default Routes;

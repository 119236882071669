import { useCallback, useEffect, useMemo, useState } from "react";
import { default as FDSAppBarTopHeader } from "@carrier-io/fds-react/patterns/AppBarTopHeader";
import Box from "@carrier-io/fds-react/Box";
import Avatar from "@carrier-io/fds-react/Avatar";
import Toolbar from "@carrier-io/fds-react/Toolbar";
import Typography from "@carrier-io/fds-react/Typography";
import { RootState } from "redux/store";
import { useAppSelector } from "redux/hooks";
import { ACCESS_TOKEN, USER } from "features/login/constants";
import ProjectLogo from "../../assets/icon/ScienceFair.svg";
import { SvgIcon } from "@carrier-io/fds-react";
import { useHistory } from "react-router-dom";

export const AppBarTopHeader = () => {
    const localUser = localStorage.getItem(USER);
    let user: any;
    if (localUser) {
        user = JSON.parse(localUser);
    } else {
        user = {
            name: "T",
            email: "test@yop.com",
        };
    }

    const history = useHistory();
    const centralComponent = useCallback<() => JSX.Element>(() => <Box />, []);

    const userInitials = useMemo(() => {
        if (user?.name) {
            const [firstName = "", lastName = ""] = user.name.split(" ");
            return `${firstName[0] ?? ""}${lastName[0] ?? ""}`;
        }

        return "";
    }, [user]);

    const rightJustifiedComponent = useCallback<() => JSX.Element>(
        () => (
            <Box
                sx={{
                    height: "100%",
                    paddingRight: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Avatar // TODO: add a handler and data binding
                    title="Profile"
                    variant="circular"
                    sx={{
                        backgroundColor: "#0068FF", // TODO: find a suitable color in the palette
                        height: 32,
                        width: 32,
                    }}
                    onClick={() => {
                        localStorage.removeItem(ACCESS_TOKEN);
                        history.push("/login");
                    }}
                >
                    <Typography variant="avatarLetter">
                        {userInitials}
                    </Typography>
                </Avatar>
            </Box>
        ),
        [userInitials]
    );

    return (
        <>
            <FDSAppBarTopHeader
                ToolbarProps={{
                    disableGutters: true,
                    sx: {
                        backgroundColor: "#192D6F", // TODO: find a suitable color in the palette
                        height: "56px", // TODO: might re-think hardcoding it like that
                    },
                    variant: "dense",
                }}
                leftJustifiedComponent={() => (
                    <SvgIcon
                        sx={{
                            height: 20,
                            width: 60,
                            mt: "5px",
                            ml: "-5px",
                        }}
                    >
                        <path
                            d="M9.39751 0H18.1961V9.76827L9.39751 0ZM0.0855615 5.73262H0C0 3.77421 0.532383 2.32917 1.59715 1.3975C2.66191 0.465835 4.30184 0 6.51693 0L18.1961 14.1747C18.1961 16.057 17.6875 17.4023 16.6702 18.2103C15.6815 18.9994 14.0654 19.3939 11.8217 19.3939L0.0855615 5.73262ZM0.0427808 9.58289L8.51337 19.4082H0.0427808V9.58289Z"
                            fill="white"
                        />
                        <path
                            d="M32.0856 0H40V5.39037H38.5169C35.9406 5.39037 34.1818 4.98633 33.2406 4.17825C32.4706 3.51277 32.0856 2.4385 32.0856 0.955437V0ZM21.9465 0H29.6185V7.81462H35.3369V11.5365H29.6185V19.3797H21.9465V0Z"
                            fill="white"
                        />
                    </SvgIcon>
                )}
                centralComponent={centralComponent} // TODO: should not be included, but otherwise it breaks the layout rn
                rightJustifiedComponent={rightJustifiedComponent}
                AppBarProps={{
                    position: "fixed",
                    sx: {
                        boxShadow: "none",
                    },
                }}
            />
            <Toolbar disableGutters variant="dense" sx={{ height: "56px" }} />
        </>
    );
};

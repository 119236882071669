import { ThemeOptions } from "@carrier-io/fds-react/styles";
import { components } from "./components";
import { palette } from "./palette";
import { sizes } from "./sizes";

export const themeOptions: ThemeOptions = {
    components,
    palette,
    sizes,
};

import { useEffect, useMemo } from "react";
import * as LaunchDarkly from "launchdarkly-js-client-sdk";
import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";

export const getUser = () => {
    const stringUser = localStorage.getItem("user");
    const { email, name, id } = JSON.parse(
        stringUser ? stringUser : '{"name":""}'
    );

    const firstName = email.substring(0, email.indexOf("."));
    const lastName = email.substring(email.indexOf(".") + 1, email.length);

    const user: LaunchDarkly.LDUser = {
        key: id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        custom: {
            groups: ["Google"],
        },
    };
    return user;
};

export const FeatureFlagProvider: any = withLDProvider({
    clientSideID: String(process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID),
    user: { key: "anonymous" },
})(({ children }) => {
    const LDClient = useLDClient();

    const user = useMemo<LaunchDarkly.LDUser>(() => {
        const stringUser = localStorage.getItem("user");
        const { email, name, id } = JSON.parse(
            stringUser ? stringUser : '{"name":"", "email":"foo@bar.com"}'
        );
        const firstName = email?.substring(0, email.indexOf("."));
        const lastName = email?.substring(email.indexOf("."), email.length);
        return {
            key: id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            custom: {
                groups: ["Google"],
            },
        };
    }, []);

    useEffect(() => {
        if (user?.email) {
            const { email, key, firstName = "", lastName = "" } = user;
            LDClient?.identify({
                key,
                email,
                custom: { firstName, lastName },
            });
        }
    }, [LDClient, user]);

    return <>{children}</>;
});

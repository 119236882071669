import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";
import { UserState } from "../constants";

export const fetchLogin = createAsyncThunk<
    UserState,
    { username?: string; password?: string },
    { state: RootState }
>("login/fetchLogin", async ({ username, password }, { rejectWithValue }) => {
    try {
        const response =
            username == "willis.carrier@carrier.com" && password
                ? {
                      status: "SUCCESS",
                      user: {
                          name: "Willis Carrier",
                          email: "willis.carrier@carrier.com",
                          id: "3f47d8e6",
                      },
                  }
                : username == "margaret.ingles@carrier.com" && password
                ? {
                      status: "SUCCESS",
                      user: {
                          name: "Margaret Ingles",
                          email: "margaret.ingles@carrier.com",
                          id: "a3bb189e",
                      },
                  }
                : {
                      status: "error",
                      message: "Cannot find account with these credentials.",
                  };

        if (response.status === "SUCCESS") {
            const dataObject: UserState = {
                pending: false,
                isLoggedIn: true,
                user: response.user,
                errorMessage: null,
            } as UserState;
            return dataObject;
        } else {
            const dataObject: UserState = {
                pending: false,
                isLoggedIn: false,
                user: null,
                errorMessage: response.message,
            } as UserState;
            return dataObject;
        }
    } catch (error) {
        return rejectWithValue((error as { message: string }).message);
    }
});

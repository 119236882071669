import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./features/App/App";
import { DemoThemeProvider } from "./theme/theme";
import CssBaseline from "@carrier-io/fds-react/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import { Loader } from "./components/Loader";
import { LaunchDarklyLogger } from "./features/App/LaunchDarklyLogger";
import { FeatureFlagProvider } from "./components/FeatureFlagProvider/FeatureFlagProvider";
import { Provider } from "react-redux";
import { IntercomProvider } from "react-use-intercom";
import store from "./redux/store";

const rootNode = document.getElementById("root");

const INTERCOM_APP_ID = "mcilztdk";

ReactDOM.render(
    <React.StrictMode>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
        ></meta>
        <IntercomProvider appId={INTERCOM_APP_ID}>
            <Provider store={store}>
                <DemoThemeProvider>
                    <FeatureFlagProvider>
                        <CssBaseline />
                        <BrowserRouter>
                            <Suspense fallback={Loader()}>
                                <App />
                            </Suspense>
                            <LaunchDarklyLogger />
                        </BrowserRouter>
                    </FeatureFlagProvider>
                </DemoThemeProvider>
            </Provider>
        </IntercomProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

import { useMemo } from "react";

import { LDFlagValue } from "launchdarkly-js-client-sdk";
import { useFeatureFlags } from "./useFeatureFlags";

export function useCheckFeatureFlagOn<FlagValue = boolean>(
    flag: any
): FlagValue {
    const { loaded, initialized, flags } = useFeatureFlags();

    return useMemo((): FlagValue => {
        const f: LDFlagValue = flags[flag];
        return loaded && initialized ? f : false;
    }, [flags, flag]);
}
